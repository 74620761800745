import $ from 'jquery';

if (typeof window.chatUrl == "string") {

  console.log("Loading Emotes & Badges...")

  window.emoteMap = new Map();
  window.bitBadgeMap = new Map();
  window.subGifterBadgeMap = new Map();

  // Load Emotes
  $.getJSON(emotesUrl, function(data){
    $.each(data, function(k, v) {
      window.emoteMap.set(v.code, v.url);
    });
  });

  // Load Badges
  $.getJSON(badgesUrl, function(data){
    $.each(data['bit'], function(k, v) {
      window.bitBadgeMap.set(v.version, v.url);
    });
    $.each(data['sub-gifter'], function(k, v) {
      window.subGifterBadgeMap.set(v.version, v.url);
    });
  });

}
