import $ from 'jquery';

if (typeof window.youtubeSlug == "string") {

  console.log("Loading YouTube Video & Chat...")

  window.onPlayerReady=function(event) {
    event.target.playVideo();
  }

  let from = 0;
  let to = 0;
  let displayMess;
  let checkInt;

  window.onPlayerStateChange=function(event) {
    if (event.data == YT.PlayerState.PLAYING) {

      // Grab the last 30 seconds of messages and display
      grabBackfillMessages();

      grabMessages();

      startInterval();

    } else if (event.data == YT.PlayerState.PAUSED) {

      clearInterval(checkInt);

      if (typeof displayMess !== 'undefined') {
        clearInterval(displayMess);
      }

    } else if (event.data == YT.PlayerState.BUFFERING) {
      // Clear the message list and stop the timer
      $(".chatmessages").empty();

      if (typeof displayMess !== 'undefined') {
        clearInterval(displayMess);
      }
    }
  }


  function grabMessages() {
    console.log(" Grabbing messages...")
    if (window.youtubeplayer.getCurrentTime() > 0) {

      from = +window.youtubeplayer.getCurrentTime().toFixed() + 1
      to   = +window.youtubeplayer.getCurrentTime().toFixed() + 20

      getMessages(from, to);
    }
  }


  // Grab messages every 20 seconds
  function startInterval() {
    checkInt = setInterval(function() {
      grabMessages()
    }, 20000)
  }

  function displayMessages(data) {
    displayMess = setInterval(function(){

      for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        var from = window.youtubeplayer.getCurrentTime().toFixed();
        var to   = window.youtubeplayer.getCurrentTime().toFixed() + ".999";

        if (window.inRange(parseFloat(obj.offset), from, to)) {
          //console.log(obj.offset + obj.body);

          var msgelement = document.getElementById("message_" + obj.id);

          // If it isn't "undefined" and it isn't "null", then it exists.
          if(typeof(msgelement) != 'undefined' && msgelement != null){
            // do nothing because it exists
          } else{
            // Add the chat message
            $(".chatmessages").append(
              "<div class='message' id='message_" + obj.id + "'>" +
              getBadges(obj.badges) +
              "<span class='author' style='color:" + obj.user_colour + "'>" +
              obj.display_name + "</span> " +
              "<span class='sep'>:</span> " +
              "<span class='content'>" + formatMessage(obj.body_parsed) + "</span></div>"
            )

            // Remove old messages and then scroll to the bottom
            window.removeOldMessages();
            window.updateScroll();
          }
        }
      }

    }, 1000)
  }


  // Get messages from the API
  function getMessages(from, to) {
    $.get( chatUrl + "?from=" + from + "&to=" + to )
      .done(function( data ) {
        displayMessages(data);
      });
  }


  // Grab and display the last 30 seconds of messages
  function grabBackfillMessages() {
    if (window.youtubeplayer.getCurrentTime() > 65) {
      from = +window.youtubeplayer.getCurrentTime().toFixed() - 60
      to   = +window.youtubeplayer.getCurrentTime().toFixed()

      getBackfillMessages(from, to);
    }
  }


  // Get messages from the API
  function getBackfillMessages(from, to) {
    $.get( chatUrl + "?kind=backfill&from=" + from + "&to=" + to )
      .done(function( data ) {
        window.displayBackfilledMessages(data);
      });
  }
}
