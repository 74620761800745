if (typeof window.chatUrl == "string") {

  // Scroll to the bottom
  window.updateScroll=function() {
    var element = document.getElementById("chatmessages");
    element.scrollTop = element.scrollHeight;
  }

  // Removes all messages
  window.removeAllMessages=function() {
    element = document.getElementById("chatmessages")
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }

  // If there are 40 or more messages, delete the first
  window.removeOldMessages=function() {
    element = document.getElementById("chatmessages")
    if (element && element.childElementCount >= 40){
      element.removeChild(element.firstElementChild)
    }
  }

  function getBadgeUrl(t) {
    switch (t.id) {
      case "moderator":
        return "https://static-cdn.jtvnw.net/badges/v1/3267646d-33f0-4b17-b3df-f923a41db1d0/1";
      case "vip":
        return "https://static-cdn.jtvnw.net/badges/v1/b817aba4-fad8-49e2-b88a-7cc744dfa6ec/1";
      case "broadcaster":
        return "https://static-cdn.jtvnw.net/badges/v1/5527c58c-fb7d-422d-b71b-f309dcb85cc1/1";
      case "partner":
        return "https://static-cdn.jtvnw.net/badges/v1/d12a2e27-16f6-41d0-ab77-b780518f00a3/1";
      case "glitchcon2020":
        return "https://static-cdn.jtvnw.net/badges/v1/1d4b03b9-51ea-42c9-8f29-698e3c85be3d/1";
      case "staff":
        return "https://static-cdn.jtvnw.net/badges/v1/d97c37bd-a6f5-4c38-8f57-4e4bef88af34/1";
      case "founder":
        return "https://static-cdn.jtvnw.net/badges/v1/511b78a9-ab37-472f-9569-457753bbe7d3/1";
      case "premium":
        return "https://static-cdn.jtvnw.net/badges/v1/bbbe0db0-a598-423e-86d0-f9fb98ca1933/1";
      case "turbo":
        return "https://static-cdn.jtvnw.net/badges/v1/bd444ec6-8f34-4bf9-91f4-af1e3428d80f/1";
      case "bits":
        return window.bitBadgeMap.get(t.version);
      case "sub-gifter":
        return window.subGifterBadgeMap.get(t.version);
    }
  }


  // Parses the user badges in a message and replaces them with the image
  window.getBadges=function(badges) {
    if (!badges || 0 === badges.length) return [];
    const e = [];
    return badges.forEach(t => {
      const n = getBadgeUrl(t);
      if (n !== undefined) {
        n && e.push(`<img class="user-badge" src="${n}" alt="${t.id}" title="${t.id}"/>`)
      }
    }), e.join("");
  }

  // Parses the emotes in a message and replaces them with the image
  window.formatMessage=function(message) {
    const e = message.split(" ");
    let n = "";
    e.forEach(t => {
      const e = window.emoteMap.get(t);
      n += e ? `<img class="emote-image" src="${e}" alt="${t}" title="${t}"/> ` : t + " "
    })
    return n.trim()
  }

  window.displayBackfilledMessages=function(data) {
    // Remove all messages
    window.removeAllMessages();

    for(var i = 0; i < data.length; i++) {
      var obj = data[i];

      var msgelement = document.getElementById("message_" + obj.id);

      // If it isn't "undefined" and it isn't "null", then it exists.
      if(typeof(msgelement) != 'undefined' && msgelement != null){
        // do nothing because it exists
      } else{
        // Add the chat message
        $(".chatmessages").append(
          "<div class='message' id='message_" + obj.id + "'>" +
          getBadges(obj.badges) +
          "<span class='author' style='color:" + obj.user_colour + "'>" +
          obj.display_name + "</span> " +
          "<span class='sep'>:</span> " +
          "<span class='content'>" + formatMessage(obj.body_parsed) + "</span></div>"
        )

        // Scroll to the bottom
        window.updateScroll();
      }
    }
  }


  window.inRange=function(x, min, max) {
    return ((x-min)*(x-max) <= 0);
  }

}
